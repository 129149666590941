@import (reference) "../styles/variables.less";

.settings-page {

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: @spacing-large;
  max-width: 600px;
  margin: 0 auto;

  .piece-type-selector,
  .include-inverses,
  .include-twists,
  .buffer-settings,
  .letter-scheme,
  .selected-letters {
    width: 100%;
    margin-bottom: @spacing-large;
    font-size: normal;

    select,
    input[type="text"],
    input[type="checkbox"] {
      margin-top: @spacing-small;
      padding: @spacing-small;
      font-size: normal;
      color: @body-text-color;
      background-color: @input-bg-color;
      border: 1px solid @border-color;
    }

    input[type="text"] {
      width: 100%;
    }

    input[type="checkbox"] {
      margin-top: 0;
      margin-right: @spacing-medium;
    }

    label {
      display: block;
      color: @body-text-color;
    }

    .error {
      color: @text-color-danger;
    }
  }

  .include-inverses,
  .include-twists {
    label {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }
  }

  .piece-type-selector {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: stretch;

    button {
      width: 50%;
    }
  }

  .settings-close-button {
    display: flex;
    justify-content: flex-end;
    margin-top: @spacing-large;
  }
}