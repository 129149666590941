@import (reference) "../styles/variables.less";

.trainer-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;


  .countdown {
    font-family: 'Digital';
    font-size: 5em;
    padding: @spacing-medium;
    padding-top: @spacing-small;
  }

  .instructions {
    font-size: smaller;
  }

  .start-button-container {
    padding: @spacing-large;
  }
}
