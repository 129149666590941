.connection-status span {
  margin-right: 1em;
  display: inline-block;
}

.settings-page {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  padding: 2em;
  display: flex;
}

.settings-page .piece-type-selector, .settings-page .include-inverses, .settings-page .include-twists, .settings-page .buffer-settings, .settings-page .letter-scheme, .settings-page .selected-letters {
  font-size: normal;
  width: 100%;
  margin-bottom: 2em;
}

.settings-page .piece-type-selector select, .settings-page .include-inverses select, .settings-page .include-twists select, .settings-page .buffer-settings select, .settings-page .letter-scheme select, .settings-page .selected-letters select, .settings-page .piece-type-selector input[type="text"], .settings-page .include-inverses input[type="text"], .settings-page .include-twists input[type="text"], .settings-page .buffer-settings input[type="text"], .settings-page .letter-scheme input[type="text"], .settings-page .selected-letters input[type="text"], .settings-page .piece-type-selector input[type="checkbox"], .settings-page .include-inverses input[type="checkbox"], .settings-page .include-twists input[type="checkbox"], .settings-page .buffer-settings input[type="checkbox"], .settings-page .letter-scheme input[type="checkbox"], .settings-page .selected-letters input[type="checkbox"] {
  font-size: normal;
  color: #ccc;
  background-color: #333;
  border: 1px solid #ccc;
  margin-top: .5em;
  padding: .5em;
}

.settings-page .piece-type-selector input[type="text"], .settings-page .include-inverses input[type="text"], .settings-page .include-twists input[type="text"], .settings-page .buffer-settings input[type="text"], .settings-page .letter-scheme input[type="text"], .settings-page .selected-letters input[type="text"] {
  width: 100%;
}

.settings-page .piece-type-selector input[type="checkbox"], .settings-page .include-inverses input[type="checkbox"], .settings-page .include-twists input[type="checkbox"], .settings-page .buffer-settings input[type="checkbox"], .settings-page .letter-scheme input[type="checkbox"], .settings-page .selected-letters input[type="checkbox"] {
  margin-top: 0;
  margin-right: 1.2em;
}

.settings-page .piece-type-selector label, .settings-page .include-inverses label, .settings-page .include-twists label, .settings-page .buffer-settings label, .settings-page .letter-scheme label, .settings-page .selected-letters label {
  color: #ccc;
  display: block;
}

.settings-page .piece-type-selector .error, .settings-page .include-inverses .error, .settings-page .include-twists .error, .settings-page .buffer-settings .error, .settings-page .letter-scheme .error, .settings-page .selected-letters .error {
  color: #b54;
}

.settings-page .include-inverses label, .settings-page .include-twists label {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.settings-page .piece-type-selector {
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  width: 100%;
  display: flex;
}

.settings-page .piece-type-selector button {
  width: 50%;
}

.settings-page .settings-close-button {
  justify-content: flex-end;
  margin-top: 2em;
  display: flex;
}

.prompt {
  padding: 1.2em;
  font-size: xxx-large;
}

.moves {
  height: 2em;
  margin-bottom: 1em;
}

.summary {
  max-height: 100%;
  margin-bottom: 2em;
  padding: 1.2em 1.2em 2em;
  font-size: smaller;
  overflow: auto;
}

.summary table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 1.2em;
}

.summary table th, .summary table td {
  text-align: left;
  border: 1px solid #ccc;
  padding: .5em;
}

.summary table th, .summary table tr:nth-child(2n) {
  background-color: #222;
}

.progress-bar {
  background-color: #333;
  border-radius: 4px;
  width: 100%;
  height: 8px;
  position: relative;
  overflow: hidden;
}

.progress-bar .progress-indicator {
  background-color: #337ab7;
  border-radius: 4px;
  width: 0;
  height: 100%;
  transition: width .6s;
  position: absolute;
  top: 0;
  left: 0;
}

.trainer-page {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.trainer-page .countdown {
  padding: .5em 1.2em 1.2em;
  font-family: Digital;
  font-size: 5em;
}

.trainer-page .instructions {
  font-size: smaller;
}

.trainer-page .start-button-container {
  padding: 2em;
}

.not-connected-message {
  text-align: center;
  padding: 2em;
}

.not-connected-message .icon {
  color: #46f;
  padding: .5em;
  font-size: 3em;
}

@font-face {
  font-family: Digital;
  src: local(DSEG7-Classic-MINI), url("DSEG7ClassicMini-Italic.9d60388c.ttf") format("truetype");
}

@font-face {
  font-family: Rubik;
  src: local(Rubik), url("Rubik-Regular.0baeaf42.ttf") format("truetype");
}

body {
  color: #ccc;
  background: #111;
  width: 100vw;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  font-family: Rubik, sans-serif;
  font-size: 14pt;
  overflow: hidden auto;
}

@media screen and (width <= 576px) {
  body {
    font-size: 11pt;
  }
}

body * {
  box-sizing: border-box;
}

body a {
  color: #ccc;
  text-decoration: none;
}

body a:hover {
  color: #fff;
}

body .header {
  text-align: right;
  background: #222;
  border-bottom: 1px solid #000;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 3em;
  padding: 2em;
  display: flex;
  position: sticky;
  top: 0;
  box-shadow: 0 0 1em #000;
}

body .container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

body .footer {
  background-color: #222;
  border-top: 1px solid #000;
  justify-content: right;
  align-items: center;
  width: 100%;
  padding: 1.2em 2em;
  display: flex;
  position: fixed;
  bottom: 0;
  box-shadow: 0 0 1em #000;
}

body .footer span {
  margin-left: .5em;
}

.btn {
  color: #fff;
  cursor: pointer;
  text-shadow: 0 1px 3px #000;
  background: #29e;
  border: 1px solid #ddd;
  border-radius: 1em;
  margin: .5em;
  padding: .5em 1.2em;
  font-family: Rubik, sans-serif;
  font-size: large;
}

@media screen and (width <= 576px) {
  .btn {
    font-size: medium;
  }
}

.btn.btn-large {
  padding: .5em 2em;
  font-size: x-large;
}

.btn.btn-default {
  color: #eee;
  background: #555;
  border: 1px solid #777;
}

.btn.btn-default:hover {
  background: #444;
}

.btn.btn-primary {
  color: #eee;
  background: #3b6;
  border: 1px solid #184;
}

.btn.btn-primary:hover {
  background: #3a6;
}

.btn.btn-danger {
  color: #eee;
  background: #b54;
  border: 1px solid #833;
}

.btn.btn-danger:hover {
  background: #833;
}

.digital {
  color: #fff;
  font-family: Digital;
  font-size: 4em;
}

/*# sourceMappingURL=index.01963ad1.css.map */
