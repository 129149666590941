@import (reference) "../styles/variables.less";

.not-connected-message {
  padding: @spacing-large;
  text-align: center;

  .icon {
    color: #46f;
    font-size: 3em;
    padding: @spacing-small;
  }
}